import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Review from "./Review";
import DistributionChart from "./DistributionChart";
import {ToastContainer, toast} from 'react-toastify';

// List of prohibited words and regex patterns
const PROHIBITED_PATTERNS = [
  // Extreme profanity
  /b[\W_]*i[\W_]*t[\W_]*c[\W_]*h/i,
  /c[\W_]*u[\W_]*n[\W_]*t/i,
  /mother[\W_]*f[\W_]*u[\W_]*c[\W_]*k[\W_]*e[\W_]*r/i,
  /n[\W_]*i[\W_]*g[\W_]*g[\W_]*e[\W_]*r/i,
  /n[\W_]*i[\W_]*g[\W_]*g[\W_]*a/i,

  // Explicit sexual content
  /porn/i, /xxx/i, /nude/i,

  // Discrimination & Hate Speech
  /racist/i, /sexist/i, /homophobic/i, /bigot/i,
  /homo/i, /lesbo/i, /tranny/i, /f[\W_]*a[\W_]*g/i,
  /retard/i, /slut/i, /whore/i, /bimbo/i,

  // Violent / Criminal Activity
  /murder/i, /suicide/i,
  /terrorist/i, /rape/i, /torture/i,

];


const WARNING_PATTERNS = [
  // Mild profanity
  /f[\W_]*u[\W_]*c[\W_]*k/i,
  /s[\W_]*h[\W_]*i[\W_]*t/i,
  /a[\W_]*s[\W_]*s/i,
  /d[\W_]*a[\W_]*m[\W_]*n/i,
  /bastard/i,
  /sex/i, /kill/i,
    // Illegal Drugs
  /cocaine/i, /heroin/i, /weed/i, /marijuana/i,
  /meth/i, /crack/i, /ecstasy/i, /stoned/i
];

const RATE_LIMIT_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const MAX_PER_COURSE = 2;
const MAX_TOTAL = 5;

const canSubmitReview = (courseId) => {
  const now = Date.now();
  
  // Fetch stored review timestamps
  let reviewHistory = JSON.parse(localStorage.getItem("reviewHistory")) || {};

  // Clean up old timestamps
  Object.keys(reviewHistory).forEach((key) => {
    reviewHistory[key] = reviewHistory[key].filter(
      (timestamp) => now - timestamp < RATE_LIMIT_TIME
    );
    if (reviewHistory[key].length === 0) {
      delete reviewHistory[key];
    }
  });

  // Get total reviews in the last 5 minutes
  const totalReviews = Object.values(reviewHistory).reduce(
    (acc, timestamps) => acc + timestamps.length,
    0
  );

  // Get reviews for this course
  const courseReviews = reviewHistory[courseId] || [];

  if (courseReviews.length >= MAX_PER_COURSE) {
    toast.warn(`You can't post too many reviews for this course at once.`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });
    return false;
  }

  if (totalReviews >= MAX_TOTAL) {
    toast.warn(`You can only post ${MAX_TOTAL} reviews across all courses every 5 minutes.`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });
    return false;
  }

  return true;
};

const recordReviewSubmission = (courseId) => {
  const now = Date.now();
  let reviewHistory = JSON.parse(localStorage.getItem("reviewHistory")) || {};

  if (!reviewHistory[courseId]) {
    reviewHistory[courseId] = [];
  }

  reviewHistory[courseId].push(now);
  localStorage.setItem("reviewHistory", JSON.stringify(reviewHistory));
};



const CoursePage = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({
    semester: "",
    difficulty_rating: "",
    overall_rating: "",
    professor: "",
    online_course: "",
    attendance_mandatory: "",
    lectures_recorded: "",
    workload: "",
    exam_format: "",
    textbook_required: "",
    take_again: "",
    grade: "",
    description: "",
    tags: [],
    is_flagged: false,
  });
  const [isDirty, setIsDirty] = useState(false);
  const [distribution, setDistribution] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const reviewFormRef = useRef(null); // Reference for the review form
  const reviewsRef = useRef(null);
  const navigate = useNavigate(); // React Router's navigate hook



  const tagOptions = [
    "Intense Lectures",
    "Difficult Exams",
    "Real Life Application",
    "Bird Course",
    "Graded by Few Things",
    "Assignment Heavy",
    "Reading Heavy",
    "Group Projects",
    "Weekly Quizzes",
    "Interesting Content",
    "Participation Counts",
    "Friendly Professor",
    "Fast Paced",
    "Strict Grading",
    "Attend Lectures to Do Well",
    "No Exams",
    "Do Readings To Do Well",
    "Get Ready To Present",
    "Has Tutorials/Practicals",
    "Take Home Exam",
    "Boring Lectures",
    "Elective",
    "Memorization Heavy"
  ];

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const courseResponse = await fetch(
          `${BASE_URL}/api/courses/${courseId}/`
        );
        if (!courseResponse.ok)
          throw new Error("Failed to fetch course details");
        const courseData = await courseResponse.json();
        setCourse(courseData.course);
        setReviews(courseData.reviews);

        calculateDistribution(courseData.reviews);
      } catch (error) {
        console.error("Error fetching course data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    setSubmitted(false);
    fetchCourseData();
  }, [courseId, submitted]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.returnValue = 'Are you sure you want to leave? Any unsaved data will be lost!';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);


  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewReview((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setIsDirty(true);
  };

  // Handle tag selection
  const handleTagChange = (tag) => {
    setNewReview((prevState) => {
      const tags = [...prevState.tags];
      if (tags.includes(tag)) {
        // Remove tag if already selected
        tags.splice(tags.indexOf(tag), 1);
      } else if (tags.length < 10) {
        // Add tag if not already selected and if the limit is not reached
        tags.push(tag);
      }
      return { ...prevState, tags };
    });
    setIsDirty(true);
  };

  const handleSubmitReview = async (event) => {
    event.preventDefault();

    if (!canSubmitReview(courseId)) return;

    let shouldFlagReview = false;

    for (let pattern of PROHIBITED_PATTERNS) {
      if (pattern.test(newReview.description)) {
        toast.error("Your review contains prohibited language. Please revise your text.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        return;
      }
    }

    for (let pattern of WARNING_PATTERNS) {
      if (pattern.test(newReview.description)) {
        shouldFlagReview = true;
        break;
      }
    }

    if (newReview.tags.length < 3){
      toast.warn('Please select at least 3 tags', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    const csrftoken = Cookies.get("csrftoken");

    try {
      const reviewData = {
        ...newReview,
        is_flagged: shouldFlagReview,
      };

      console.log("Tags sent: " + newReview.tags);
      console.log(newReview);
      const response = await fetch(
        `${BASE_URL}/api/reviews/${courseId}/submit_review/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify(reviewData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to submit review");
      }
      

      const updatedReview = await response.json();
      console.log(updatedReview);
      setReviews((prevReviews) => [...prevReviews, updatedReview]);
      calculateDistribution([...reviews, updatedReview]);

      setNewReview({
        semester: "",
        difficulty_rating: 1,
        overall_rating: 1,
        professor: "",
        online_course: true,
        attendance_mandatory: "",
        lectures_recorded: true,
        workload: "",
        textbook_required: true,
        take_again: true,
        grade: "",
        description: "",
        exam_format: "",
        tags: [],
        is_flagged: false,

      });
      setIsDirty(false);
      setSubmitted(true);
      recordReviewSubmission(courseId);
      toast.success('Review submitted successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
      });
      scrollToReviews();
    } catch (error) {
      console.error("Error submitting review:", error);
      setError(error.message);
    }
  };

  const calculateDistribution = (reviews) => {
    const totalReviews = reviews.length;
    if (totalReviews === 0) return;

    const ratings = {
      overall: 0,
      difficulty: 0,
      takeAgainYes: 0,
      textbookRequired: 0,
      ratingCounts: [0, 0, 0, 0, 0],
    };

    reviews.forEach((review) => {
      ratings.overall += review.overall_rating;
      ratings.difficulty += review.difficulty_rating;
      if (review.take_again) ratings.takeAgainYes += 1;
      if (review.textbook_required) ratings.textbookRequired += 1;

      // Increment the count for each specific rating (1-5)
      if (review.overall_rating >= 1 && review.overall_rating <= 5) {
        ratings.ratingCounts[review.overall_rating - 1] += 1;
      }
    });

    setDistribution({
      avgOverall: (ratings.overall / totalReviews).toFixed(1),
      avgDifficulty: (ratings.difficulty / totalReviews).toFixed(1),
      takeAgainPercentage: (
        (ratings.takeAgainYes / totalReviews) *
        100
      ).toFixed(0),
      textbookRequiredPercentage: (
        (ratings.textbookRequired / totalReviews) *
        100
      ).toFixed(0),
      ratings: ratings.ratingCounts.reverse(),
    });
  };

  // Scroll to the review form when the button is clicked
  const scrollToReviewForm = () => {
    reviewFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToReviews = () => {
    reviewsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (loading) return <p>Loading course details...</p>;
  if (error) return <p>Error: {error}</p>;

  function getColorClass(value, type) {
    if (type === "difficulty") {
      // Lower difficulty is better (green for easy, red for hard)
      return value <= 2 ? "green" : value <= 3.5 ? "yellow" : "red";
    } else if (type === "quality") {
      // Higher quality is better (green for good, red for bad)
      return value >= 4 ? "green" : value >= 2.5 ? "yellow" : "red";
    } else if (type === "retake") {
      // Higher retake rate is better (green for high, red for low)
      return value >= 75 ? "green" : value >= 50 ? "yellow" : "red";
    }
    return "";
  }


  return (
    <div className="course-page">
      {course && (
        <div style={{ marginTop: '50px' }}>
          <h2>
            {course.code}: {course.title}
          </h2>
          <p>{course.description}</p>
        </div>
      )}

      <div className="review-distribution">
        {distribution ? (
          <>
            <div className="avg-dif-retake">
              <div
                className={`info-box ${getColorClass(
                  distribution.avgDifficulty,
                  "difficulty"
                )}`}
              >
                <p>Average Difficulty: {distribution.avgDifficulty}</p>
              </div>
              <div
                className={`info-box ${getColorClass(
                  distribution.avgOverall,
                  "quality"
                )}`}
              >
                <p>Overall <br /> Quality: {distribution.avgOverall}</p>
              </div>
              <div
                className={`info-box ${getColorClass(
                  distribution.takeAgainPercentage,
                  "retake"
                )}`}
              >
                <p>Would Take Again: {distribution.takeAgainPercentage}%</p>
              </div>
            </div>

            <h3>Rating Distribution</h3>
            <div className="distribution-chart">
              <DistributionChart distribution={distribution} />
            </div>
          </>
        ) : (
          <p style={{ fontSize: '1.3em' }}> No reviews yet. <br /> Be the first to review! <br />
          It only takes a minute to share your experience and help your peers. </p>
        )}
      </div>

      <button className="submit-review-button" onClick={scrollToReviewForm} ref={reviewsRef}>
        Rate My Course
      </button>

      {reviews.length > 0 && <h3>Reviews</h3>}
      <div className="review-container">
        {reviews.map((review, index) => (
          <Review key={index} review={review} />
        ))}
      </div>

      <h3 style={{ marginTop: '50px' }}>Submit a Review</h3>
      <form
        onSubmit={handleSubmitReview}
        ref={reviewFormRef}
        className="review-form"
      >
        <div className="review-form-row">
          <label className='lol'>
            Semester Taken:
            <select
              name="semester"
              value={newReview.semester}
              onChange={handleChange}
              required
            >
              <option value="">Select Semester</option>
              <option value="Fall 2024">Fall 2024</option>
              <option value="Summer 2024">Summer 2024</option>
              <option value="Winter 2024">Winter 2024</option>
              <option value="Fall 2023">Fall 2023</option>
              <option value="Summer 2023">Summer 2023</option>
              <option value="Winter 2023">Winter 2023</option>
              <option value="Fall 2022">Fall 2022</option>
              <option value="Summer 2022">Summer 2022</option>
              <option value="Winter 2022">Winter 2022</option>
              <option value="Fall 2021">Fall 2021</option>
              <option value="Summer 2021">Summer 2021</option>
              <option value="Winter 2021">Winter 2021</option>
            </select>
          </label>

          <label>
            Professor:
            <input
              type="text"
              name="professor"
              value={newReview.professor}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Grade:
            <select name="grade" value={newReview.grade} onChange={handleChange} required>
              <option value="">Select Grade</option>
              <option value="A+">A+</option>
              <option value="A">A</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B">B</option>
              <option value="B-">B-</option>
              <option value="C+">C+</option>
              <option value="C">C</option>
              <option value="C-">C-</option>
              <option value="D+">D+</option>
              <option value="D">D</option>
              <option value="D-">D-</option>
              <option value="F">F</option>
              <option value="CR/NCR"> CR/NCR </option>
              <option value="N/A">Not Available Yet</option>
              <option value="RNS">Rather Not Say</option>
            </select>
          </label>
        </div>

        <div className="review-form-row">
          <label>
            Course Difficulty:
            <select
              name="difficulty_rating"
              value={newReview.difficulty_rating}
              onChange={handleChange}
              required
            >
              <option value="">Select Difficulty</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </label>

          <label>
            Course Quality: 
            <select
              name="overall_rating"
              value={newReview.overall_rating}
              onChange={handleChange}
              required
            >
              <option value="">Select Quality</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </label>
        </div>



        <div className="yes-no-inline-single">
          Was this an online course?
          <div className="form-radio">
            <label>
              Yes
              <input
                type="radio"
                name="online_course"
                checked={newReview.online_course === true}
                onChange={() => {
                  setNewReview((prev) => ({ ...prev, online_course: true }))
                  setIsDirty(true);
                  }
                }
                required
              />
            </label>
            <label>
              No
              <input
                type="radio"
                name="online_course"
                checked={newReview.online_course === false}
                onChange={() => {
                  setNewReview((prev) => ({ ...prev, online_course: false }))
                  setIsDirty(true);
                }
                }
                required
              />
            </label>
          </div>
        </div>

        {/* <label className="yes-no-inline">
          What was the exam format?
          <label>
            MCQ Only
            <input
              type="radio"
              name="exam_format"
              checked={newReview.exam_format === "MCQ Only"}
              onChange={(e) =>
                setNewReview((prev) => ({ ...prev, exam_format: e.target.value }))
              }
            />
          </label>
          <label>
            SA Only
            <input
              type="radio"
              name="exam_format"
              checked={newReview.exam_format === "SA Only"}
              onChange={(e) =>
                setNewReview((prev) => ({ ...prev, exam_format: e.target.value }))
              }
            />
          </label>
          <label>
            MCQ & SA
            <input
              type="radio"
              name="exam_format"
              checked={newReview.exam_format === "MCQ & SA"}
              onChange={(e) =>
                setNewReview((prev) => ({ ...prev, exam_format: e.target.value }))
              }
            />
          </label>
        </label> */}

        <div className="yes-no-inline-single">
          Would you take it again?
          <div className="form-radio">
            <label>
              Yes
              <input
                type="radio"
                name="take_again"
                checked={newReview.take_again === true}
                onChange={() => {
                  setNewReview((prev) => ({ ...prev, take_again: true }))
                  setIsDirty(true);
                }
                }
                required
              />
            </label>
            <label>
              No
              <input
                type="radio"
                name="take_again"
                checked={newReview.take_again === false}
                onChange={() => {
                  setNewReview((prev) => ({ ...prev, take_again: false }))
                  setIsDirty(true);
                }
                }
                required
              />
            </label>
          </div>
        </div>

        <div className="yes-no-inline-single">
          Was the textbook required?
          <div className="form-radio">
            <label>
              Yes
              <input
                type="radio"
                name="textbook_required"
                checked={newReview.textbook_required === true}
                onChange={() => {
                  setNewReview((prev) => ({ ...prev, textbook_required: true }))
                  setIsDirty(true);
                }
                }
                required
              />
            </label>
            <label>
              No
              <input
                type="radio"
                name="textbook_required"
                checked={newReview.textbook_required === false}
                onChange={() => {
                  setNewReview((prev) => ({ ...prev, textbook_required: false }))
                  setIsDirty(true);
                }
                }
                required
              />
            </label>
          </div>
        </div>

        <div className="yes-no-inline-single">
          Was attendance mandatory?
          <div className="form-radio">
            <label>
              Yes
              <input
                type="radio"
                name="attendance_mandatory"
                value="Mandatory"
                checked={newReview.attendance_mandatory === true}
                onChange={(e) => {
                  setNewReview((prev) => ({ ...prev, attendance_mandatory: true }))
                }
                }
                required
              />
            </label>
            <label>
              No
              <input
                type="radio"
                name="attendance_mandatory"
                value="Not Mandatory"
                checked={newReview.attendance_mandatory === false}
                onChange={(e) => {
                  setNewReview((prev) => ({ ...prev, attendance_mandatory: false }))
                }
                }
                required
              />
            </label>
          </div>
        </div>

        <div className="yes-no-inline-single">
          Were the lectures recorded?
          <div className="form-radio">
            <label>
              Yes
              <input
                type="radio"
                name="lectures_recorded"
                checked={newReview.lectures_recorded === true}
                onChange={() => {
                  setNewReview((prev) => ({
                    ...prev,
                    lectures_recorded: true,
                  }))
                  setIsDirty(true);
                }
                }
                required
              />
            </label>
            <label>
              No
              <input
                type="radio"
                name="lectures_recorded"
                checked={newReview.lectures_recorded === false}
                onChange={() => {
                  setNewReview((prev) => ({
                    ...prev,
                    lectures_recorded: false,
                  }))
                  setIsDirty(true);
                }
                }
                required
              />
            </label>
          </div>
        </div>

        <div className="yes-no-inline">
          How was the workload?
          <div>
            <select
              name="workload"
              value={newReview.workload}
              onChange={(e) => {
                setNewReview((prev) => ({ ...prev, workload: e.target.value }))
                setIsDirty(true);
              }
              }
              required
            >
              <option value="">Select Workload</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </div>
        </div>

        <div className="yes-no-inline">
          What was the exam format?
          <div>
            <select
              name="exam_format"
              value={newReview.exam_format}
              onChange={(e) => {
                setNewReview((prev) => ({ ...prev, exam_format: e.target.value }))
                setIsDirty(true);
              }
              }
              required
            >
              <option value="">Select Exam Format</option>
              <option value="MCQ Only">MCQ Only</option>
              <option value="SA Only">Short Answer Only</option>
              <option value="MCQ & SA">MCQ & Short Answer</option>
            </select>
          </div>
        </div>

        <label>
          Share Your Experience
          <textarea
            name="description"
            value={newReview.description}
            onChange={(e) => {
              handleChange(e);
              e.target.setCustomValidity(''); // Clear any previous validation message
            }}
            maxLength={2100}
            minLength={100}
            required
            placeholder="What did you like / dislike about the course? Any tips or key things students should know?'"
            onInvalid={(e) => {
              if (e.target.value.length < 100) {
                e.target.setCustomValidity(`Please use a minimum of 100 characters (you are currently using ${e.target.value.length})`);
              } else {
                e.target.setCustomValidity('');
              }
            }}
          />
          <p>Characters remaining: {2100 - newReview.description.length}</p>
        </label>

        <label>
          Tags (up to 10):
          <div className="tags-inline">
            {tagOptions.map((tag) => (
              <button
                type="button"
                key={tag}
                className={`tag-button ${newReview.tags.includes(tag) ? "selected" : ""
                  }`}
                onClick={() => handleTagChange(tag)}
                disabled={
                  newReview.tags.length >= 10 && !newReview.tags.includes(tag)
                }
              >
                {tag}
              </button>
            ))}
          </div>
        </label>

        <button className='form-submit' type="submit">Submit Review</button>
        <ToastContainer/>
      </form>
    </div>
  );
};

export default CoursePage;
