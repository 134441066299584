import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CourseList from "./components/CourseList";
import CoursePage from "./components/CoursePage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SiteGuidelines from "./components/SiteGuidelines";
import HomePage from "./components/HomePage";
/* Menu Imports */
import AboutUs from "./components/AboutUs"
import OurFounder from "./components/OurFounder"
// import OurTeam from "./components/OurTeam"

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/utsc" element={<CourseList />} />{" "}
        <Route path="/utsc/courses/:courseId" element={<CoursePage />} />{" "}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
        <Route path="/site-guidelines" element={<SiteGuidelines/>} />{" "}
        <Route path="/AboutUs" element={<AboutUs/>}/>
        <Route path="/OurFounder" element={<OurFounder/>}/>
        {/*<Route path="/OurTeam" element={<OurTeam/>}/>*/}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
