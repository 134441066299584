import React from "react";

const PrivacyPolicy = () => {
  const sectionStyle = {
    marginBottom: "30px",
  };

  const headingStyle = {
    marginBottom: "10px",
    color: "#2c3e50",
  };

  const listStyle = {
    marginLeft: "20px",
    lineHeight: "1.8",
  };

  const containerStyle = {
    padding: "30px",
    maxWidth: "800px",
    margin: "auto",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "1.8",
    color: "#333",
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> Dec 15th, 2024</p>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>1. Introduction</h2>
        <p>
          Welcome to <strong>Rate My Courses</strong>. We value your privacy and are committed to protecting your
          information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information
          when you visit our website <em>[ratemycourses.org]</em> or use our services.
        </p>
        <p>
          By accessing or using the website, you agree to the terms of this Privacy Policy. If you do not agree, please refrain from using the site.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>2. Information We Collect</h2>

        <h3 style={{ marginBottom: "10px" }}>2.1 Anonymous Data</h3>
        <p>
          We operate as an anonymous platform. When you submit a course review, we do not collect personally
          identifiable information like names or email addresses.
        </p>

        <h3 style={{ marginBottom: "10px" }}>2.2 Usage Data</h3>
        <p>
          When you visit our website, <strong>non-personal information</strong> may be automatically collected for
          analytics and operational purposes. This data is collected through tools like <strong>Google Analytics</strong> and may include:
        </p>
        <ul style={listStyle}>
          <li>Browser type and version</li>
          <li>Pages visited and time spent on the site</li>
          <li>Referring website or URL</li>
          <li>General device information (e.g., operating system, screen size)</li>
        </ul>
        <p>
          While we do not directly collect IP addresses or personally identifiable tracking data, our{" "}
          <strong>third-party service providers</strong> may log certain technical information (e.g., IP addresses) for
          operational, security, and performance purposes. Any data collected by these providers is governed by their respective privacy policies.
        </p>

        <h3 style={{ marginBottom: "10px" }}>2.3 Cookies and Tracking Technologies</h3>
        <p>We use cookies and similar technologies to improve your experience and analyze website performance. Cookies help us:</p>
        <ul style={listStyle}>
          <li>Recognize your preferences when you return to the site</li>
          <li>Analyze trends and usage patterns</li>
        </ul>
        <p>You can manage or disable cookies through your browser settings. Please note that disabling cookies may affect some functionalities of the site.</p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>3. How We Use Your Information</h2>
        <p>The information we collect is used to:</p>
        <ul style={listStyle}>
          <li>Operate and maintain our website</li>
          <li>Process and display course reviews and ratings</li>
          <li>Analyze trends and improve user experience</li>
          <li>Ensure site security, prevent abuse, and detect fraudulent activities</li>
        </ul>
        <p>
          In the future, if we collect additional personal information (e.g., names or email addresses), we will update this Privacy Policy to explain how such data will be securely handled.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>4. How We Share Your Information</h2>
        <p>We do not sell or share your information with third parties for marketing purposes. However, we may share information in the following circumstances:</p>
        <h3 style={{ marginBottom: "10px" }}>4.1 Service Providers</h3>
        <p>We may share non-personal information (e.g., usage data) with trusted third-party service providers who assist us in operating and analyzing our website.</p>
        <h3 style={{ marginBottom: "10px" }}>4.2 Legal Obligations</h3>
        <p>We may disclose information when required to do so by law, such as in response to subpoenas, legal processes, or government requests.</p>
        <h3 style={{ marginBottom: "10px" }}>4.3 Protection of Rights</h3>
        <p>We may share information to protect the rights, property, or safety of <strong>Rate My Courses</strong>, its users, or others.</p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>5. Data Security</h2>
        <p>
          We implement reasonable technical and organizational measures to protect your information from unauthorized
          access, alteration, or misuse. While no system is 100% secure, we take steps to safeguard data and ensure its confidentiality.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>6. Your Rights</h2>
        <p>
          At present, our platform does not collect personal information such as names, email addresses, or user profiles.
          If we introduce user accounts or personal data collection in the future, you will have the following rights:
        </p>
        <ul style={listStyle}>
          <li><strong>Access</strong> and update your information.</li>
          <li><strong>Request deletion</strong> of your personal data.</li>
          <li><strong>Opt out</strong> of any communications, if applicable.</li>
        </ul>
        <p>We will update this Privacy Policy to reflect any new practices around personal data collection.</p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>7. User-Generated Content</h2>
        <p>
          When you submit a course review, it becomes <strong>publicly visible</strong>. Avoid sharing any personal or sensitive information in your review.
        </p>
        <p>
          If you see content that violates our guidelines or includes inappropriate material, use the <strong>Flag</strong> button to report it.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>8. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect changes in our practices or for other operational,
          legal, or regulatory reasons. Any updates will be posted on this page with a revised <strong>Effective Date</strong>.
        </p>
        <p>
          We encourage you to review this Privacy Policy regularly. Continued use of the site after updates indicates acceptance of the new policy.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>9. Contact Us</h2>
        <p>If you have any questions, concerns, or feedback about this Privacy Policy or our data practices, please contact us:</p>
        <p><strong>Email:</strong> <a href="mailto:ratemycoursess@gmail.com">ratemycoursess@gmail.com</a></p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
