import React, { useEffect, useState } from 'react'
import '../styles/homepage.css'
import { Link } from 'react-router-dom'

const BASE_URL = process.env.REACT_APP_API_BASE_URL;


const SchoolCard = ({school, image, to, numReviews}) => {

  return (
    <div className='school-card'>
      <Link to={`${to}`}>
            <img src={image} alt={school} />
            <h1>{school}</h1>
            <h2 className='num-reviews'>Reviews {numReviews}</h2>
        </Link>
    </div>
    )
}

const DisabledSchoolCard = ({school, image, to}) => {
    return (
      <div className='school-card disabled'>
            <img src={image} alt={school} />
            <h1>{school}</h1>
      </div>
      )
  }

const HomePage = () => {

  
  const [numReviews, setNumReviews] = React.useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    const fetchNumReviews = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/reviews/total_reviews`);

        if (!response.ok) {
          throw new Error("Failed to fetch number of reviews");
        }

        const data = await response.json();

        setNumReviews(data.total_reviews);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNumReviews();
  }, []);

  if (loading) return <p>Loading home...</p>;

  return (
    <main className='hp-container'>

        <div className='hp-inner'>

            <h1>Welcome to Rate My Courses!</h1>
            <br/>
            <p>Rate My Courses is a course rating and review platform for university students.</p>
            <div className='line'/>

            <h2>Select your campus below to get started</h2>

            <div className='grid-container'>
                <SchoolCard school='University of Toronto Scarborough' image='/utsc.png' to='utsc' numReviews={numReviews}/>
                <DisabledSchoolCard school='University of Toronto Missisauga' image='/comingsoon.jpg' to='utm' />
                <DisabledSchoolCard school='University of Toronto St. George' image='/comingsoon.jpg' to='utsg' />
            </div>

            <div className='line'/>

            {/* <section className='info'>
                <h2>What Is Rate My Courses?</h2>
                <p>Rate My Courses is a course rating and review platform for university students.</p>

                <h2>Our Mission</h2>
                <div>
                  <p>1. Reduce anxiety and stress associated with course selection</p>
                  <p>2. Help students make more informed decisions</p>
                  <p>3. increase confidence when going into a course</p>
                  <p>4. Reduce regret and course drop rates</p>
                  <p>5. Reduce financial penalty associated with dropping courses</p>
                  <p>5. Save time researching into courses</p>
                </div>

                <h2>Our Vision</h2>
                <p>To improve the student experience for  university students across North America. 
                  <br/>
                  <br/>
                  Starting at UTSC, we aim to expand across the three campuses and eventually to 
                  universities across North America. Through honest, student-generated reviews, 
                  we strive to simplify course selection, reduce stress, and improve academic success.
                </p>

                <h2>What Sets Us Apart</h2>
                <p>Unlike scattered group chats or generic platforms, Rate My Courses is a dedicated 
                  platform built for students, by students. Here is what sets us apart:</p>
                <br/>
                <div>
                  <p>• <b>Authentic, Anonymous Reviews:</b> Honest insights directly from students.</p>
                  <p>• <b>Comprehensive Guidance:</b> workload, bird courses, professors, exam type— we cover it all.  </p>
                  <p>• <b>Tailored for You:</b> Custom fields and tags to answer your questions about courses. </p>
                  <p>• <b>Trusted Community:</b> A supportive network of students helping each other succeed.</p>
                  <p>• <b>High Coverage:</b> Over 2,000 courses and growing</p>
                </div>
                <br/>
                <p>We&apos;re not just a platform—we&apos;re your partner in creating a smarter, stress-free academic journey.</p>

                <h2>Join Us Today And Make A Change!</h2>
                <p>Share your course reviews and help others make smarter, stress-free academic choices.</p>
            </section> */}
            
            
        </div>
    
    </main>
  )
}

export default HomePage