import React from "react";

const SiteGuidelines = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
    lineHeight: "1.6",
  };

  const sectionStyle = {
    marginBottom: "20px",
  };

  const headerStyle = {
    color: "#000",
    fontSize: "24px",
    borderBottom: "1px solid #ddd",
    paddingBottom: "10px",
  };

  const subHeaderStyle = {
    color: "#000",
    fontSize: "20px",
    marginBottom: "10px",
  };

  const listStyle = {
    marginLeft: "20px",
  };

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>Review Guidelines</h1>
      <p>
        Welcome to <strong>Rate My Courses</strong>! We’re here to help students make informed
        decisions about their courses through ratings and reviews. To keep the platform helpful,
        fair, and respectful, please follow these guidelines when sharing your experiences.
      </p>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>1. Be Honest and Constructive</h2>
        <ul style={listStyle}>
          <li>Share your genuine experience with the course.</li>
          <li>
            Highlight what you liked or disliked about the course, such as the professor, workload,
            exam difficulty, or assignments.
          </li>
          <li>Focus on your own experience. Avoid hearsay or speaking on behalf of others.</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>2. Keep It Respectful</h2>
        <ul style={listStyle}>
          <li>Focus on the course content and structure, not individuals.</li>
          <li>Use professional, constructive language.</li>
          <li>Avoid comments about personal attributes (appearance, age, gender, or personal life).</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>3. Be Clear and Concise</h2>
        <ul style={listStyle}>
          <li>Keep reviews relevant and easy to read.</li>
          <li>Avoid excessive punctuation, emojis, or slang.</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>4. No Spam or Fake Reviews</h2>
        <ul style={listStyle}>
          <li>Users may post <strong>one review per course</strong>.</li>
          <li>Do not submit duplicate reviews or reviews for courses you have not taken.</li>
          <li>
            We use automated tools and manual checks to verify suspicious reviews. Reviews flagged
            as spam or misleading will be removed.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>5. Follow Our Character Guidelines</h2>
        <ul style={listStyle}>
          <li>
            Minimum: <strong>100 characters</strong> to ensure your review provides value.
          </li>
          <li>
            Maximum: <strong>2,100 characters</strong>.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>6. Tags Matter</h2>
        <p>
          Use the provided tags to summarize your experience (e.g., “Difficult Exams,” “Friendly
          Professor,” “Group Projects”). Tags help other students quickly understand course
          highlights.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>7. Flag Inappropriate Content</h2>
        <p>
          If you see a review that violates these guidelines, use the <strong>“Flag”</strong> button to
          notify us. Flagged reviews are escalated to our moderation team for review.
        </p>
        <ul style={listStyle}>
          <li>Reviews will only be removed if they violate our guidelines.</li>
          <li>
            Flagging a review simply because you disagree with someone’s opinion will not result in
            removal.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>8. Prohibited Content</h2>
        <p>Reviews that contain the following will be <strong>removed without notice</strong>:</p>
        <ul style={listStyle}>
          <li>Profanity, vulgarity, or offensive language.</li>
          <li>Personal attacks, name-calling, or harassment.</li>
          <li>False, misleading, or defamatory claims.</li>
          <li>References to illegal activities.</li>
          <li>Content unrelated to the course experience.</li>
          <li>Sharing personal information about a professor or student (e.g., phone numbers, emails).</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>9. Consequences for Violating Guidelines</h2>
        <p>
          Reviews that do not meet these guidelines will be <strong>removed without notice</strong>. Users
          who repeatedly violate these rules may be <strong>banned from the platform</strong>.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subHeaderStyle}>Why Your Reviews Matter</h2>
        <p>Your feedback helps students:</p>
        <ul style={listStyle}>
          <li>
            <strong>Reduce anxiety and stress</strong> when choosing courses.
          </li>
          <li>
            <strong>Make more informed decisions</strong> about their academic journey.
          </li>
          <li>
            <strong>Increase confidence</strong> going into a course.
          </li>
          <li>
            <strong>Avoid regret</strong> about their course choices.
          </li>
          <li>
            <strong>Reduce course drop rates</strong>.
          </li>
          <li>
            <strong>Save money</strong> by avoiding financial penalties associated with dropping
            courses.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h3>
          By submitting your review, you confirm that your content follows our guidelines. Let’s keep
          <strong> Rate My Courses</strong> honest, respectful, and helpful for everyone!
        </h3>
        <p>
          Together, we can make <strong>Rate My Courses</strong> the best tool for students. 🚀
        </p>
      </div>
    </div>
  );
};

export default SiteGuidelines;
