import React from "react";

const AboutUs = () => {
  const containerStyle = {
    padding: "30px",
    maxWidth: "800px",
    margin: "auto",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "1.8",
    color: "#333",
  };

  const sectionStyle = {
    marginBottom: "30px",
  };

  const headingStyle = {
    marginBottom: "10px",
    color: "#2c3e50",
    fontWeight: "bold",
  };

  const listStyle = {
    marginLeft: "20px",
    lineHeight: "1.8",
  };

  const highlightStyle = {
    fontWeight: "bold",
    color: "red",
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>About Us</h1>
      
      <section style={sectionStyle}>
        <h2 style={headingStyle}>What Is Rate My Courses?</h2>
        <p>
          <strong>Rate My Courses</strong> is a course rating and review platform for university students. 
          It’s a space where students can share honest reviews, offer advice, and make course selection easier for everyone. 
          Even though some of us have already graduated, we want to ensure that future students don’t face the same challenges we did.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>The Student Struggle</h2>
        <p>
          Choosing the right courses is one of the most important decisions students make throughout their degree. 
          It directly impacts their GPA, career prospects, and self-confidence. The wrong choice can lead to stress, self-doubt, 
          and how others — peers, professors, and future employers — perceive them.
        </p>
        <p>
          At large universities, students have a vast selection of courses to choose from. But with greater flexibility comes greater stress; 
          more choices mean more uncertainty. This burden is heavier for <span style={highlightStyle}>international students who pay 10 times more </span> 
          than domestic students (at least at UofT). Dropping a course is a luxury they simply cannot afford—forcing them to stay in courses 
          that may harm their GPA, mental health, and overall university experience.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>The Problem</h2>
        <p>Course selection is too time-consuming and stressful for students.</p>
        <ul style={listStyle}>
          <li>
            <strong>Too many options, not enough guidance:</strong>
            <ul>
              <li>Course syllabi and academic advisors provide little to no guidance on course difficulty, teaching style, workload, etc.</li>
              <li>Course content isn’t publicly available, making it difficult to gauge whether a course is the right fit.</li>
              <li>Not everyone has access to upper-year students and may be too shy or intimidated to reach out.</li>
            </ul>
          </li>
          <li><strong>No centralized source of student reviews:</strong> Students rely on group chats and scattered forums for advice.</li>
          <li><strong>Expectation vs Reality:</strong> What may seem interesting and easy at the start of the semester can completely change in a matter of weeks.</li>
          <li><strong>Not enough time to decide:</strong> Refund deadlines don’t leave enough time to decide whether to stay in a course.</li>
          <li><strong>Dropping a course is expensive:</strong> Dropping courses beyond the full refund deadline (which is two weeks after the semester starts) can cost thousands of dollars.</li>
          <li>Being stuck in the wrong course can severely affect your GPA, mental health, and future prospects.</li>
        </ul>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>Our Mission</h2>
        <p>To help students make informed course-related decisions, thereby improving their mental health and academic performance.</p>
        <ul style={listStyle}>
          <li><strong>Improve Student Mental Health:</strong> Reduce course-related stress with honest, student-generated reviews.</li>
          <li><strong>Enhance Academic Success:</strong> By providing insights from past students, we help students make informed decisions, enter courses with confidence, and set themselves up for success.</li>
          <li><strong>Lower Regret & Course Drop Rates:</strong> Minimize drop rates and improve academic satisfaction.</li>
          <li><strong>Save Time:</strong> Eliminate hours of scattered research and outreach by offering a centralized source of course insights.</li>
          <li><strong>Save Money:</strong> Help students—especially international students—avoid costly course drops that can amount to thousands of dollars.</li>
        </ul>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>Our Vision</h2>
        <p>To improve the student experience for university students across North America.</p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>Join Us Today!</h2>
        <p>Share your course reviews and help others make smarter, stress-free academic choices.</p>
      </section>
    </div>
  );
};

export default AboutUs;
