import React, {useState} from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <header>
      <nav className="navbar">
        <a className="logo" href="/">
          <img src="/rmclogo.png" alt="Rate My Courses Logo" />
          <h1>Rate My Courses</h1>
        </a>

        {/* Menu Toggle for mobile */}
        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>

        {/* Menu Items */}
        <div className={`menu ${isMenuOpen ? 'active' : ''}`}>
          <a className="aboutUs" href="/AboutUs">
            <h4>About Us</h4>
          </a>
          <a href="/OurFounder">
            <h4>Founder Story</h4>
          </a>
          {/*<a href="/OurTeam">*/}
          {/*  <h4>The Team (Coming Soon)</h4>*/}
          {/*</a>*/}
          {/*<a href="mailto:ratemycoursess@gmail.com">*/}
          {/*  <h4>Add Course Request</h4>*/}
          {/*</a>*/}
          {/*<a href="mailto:ratemycoursess@gmail.com">*/}
          {/*  <h4>Add School Request</h4>*/}
          {/*</a>*/}
          <a href="mailto:ratemycoursess@gmail.com">
            <h4>Contact Us</h4>
          </a>
        </div>
        {/* <div className="nav-links">
          <a
            href="https://www.instagram.com/rate_mycourses"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a href="mailto:ratemycoursess@gmail.com" className="icon-link">
            <i className="fas fa-envelope"></i>
          </a>
        </div> */}
      </nav>
    </header>
  );
};

export default Header;
